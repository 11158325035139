import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyDXt6gAi9fZN7ggKS_HiYzFvwOXh3f9z_M',
	authDomain: 'nfzmojezdrowie.firebaseapp.com',
	projectId: 'nfzmojezdrowie',
	storageBucket: 'nfzmojezdrowie.appspot.com',
	messagingSenderId: '388573826269',
	appId: '1:388573826269:web:385287db341cc76eaedf34',
	measurementId: 'G-R3THVQWES5'
};

// Initialize Firebase
export const firebaseApp = () => initializeApp(firebaseConfig);
