export const start = {
	header: {
		pl: 'Moje Zdrowie +'
	},
	text: {
		pl: `Odzyskaj kontrolę nad swoim zdrowiem.
		Pobierz aplikację i\xa0dbaj o\xa0siebie każdego dnia. 
		Układaj dietę, kontroluj swoje parametry 
		i\xa0śledź swoją aktywność fizyczną w\xa0jednej aplikacji.`
	}
};
