import React from 'react';
import { Helmet } from 'react-helmet';

// Import components
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
	return (
		<div id="portal">
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="color-scheme" content="light only" />
				<title>NFZ Moje Zdrowie</title>
				<link
					rel="icon"
					type="image/ico"
					sizes="96x96"
					crossorigin="use-credentials"
					href="/header-logo.ico"
				/>
			</Helmet>
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
};

export default Layout;
