import React from 'react';
import { func } from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// Import translations
import { useTranslations } from 'components/utilities';

// Import helpers
import { HEADER } from './helpers';
import { HEADER_BAR_HEIGHT } from 'helpers';

const NavLinks = ({ onClick = () => {} }) => {
	const { t } = useTranslations();

	return (
		<div className="flex mt-4 flex-col justify-evenly items-center h-full gap-4 sm:mt-0 sm:gap-0 md:h-auto md:flex-row md:justify-start lg:gap-10">
			{HEADER.map(({ href, label }) => (
				<AnchorLink
					offset={HEADER_BAR_HEIGHT}
					key={href}
					onClick={onClick}
					className="text-header px-2 md:text-lg lg:text-header"
					href={href}
				>
					{t(label)}
				</AnchorLink>
			))}
		</div>
	);
};

NavLinks.propTypes = {
	onClick: func
};

export default NavLinks;
