import React from 'react';

import NFZLogo from 'assets/icons/nfz_logo.svg';
import { MEDIA_QUERY_SIZE } from 'helpers/styles';
import SideDrawer from './SideDrawer';
import NavLinks from './NavLinks';
import { Logo } from 'components/elements';
import { useMediaQuery } from 'components/utilities';

const Header = () => {
	const match = useMediaQuery(`(min-width: ${MEDIA_QUERY_SIZE.md}px)`);

	return (
		<header className="sticky top-0 bg-gray-background shadow-lg z-50">
			<div className=" w-full flex flex-row items-center justify-between py-3 px-12">
				<div className="flex items-center text-2xl gap-6">
					<NFZLogo />
					<div className="mr-3">
						<Logo />
					</div>
				</div>
				{match ? <NavLinks /> : <SideDrawer />}
			</div>
		</header>
	);
};

export default Header;
