export const functions = {
	title: { pl: 'Funkcje aplikacji' },
	content: {
		pl: 'Aplikacja zbudowana jest z\xa0modułów poświęconych diecie, lekom, aktywności fizycznej oraz parametrom życiowym. Wszystkie moduły w\xa0połączeniu ze sobą mają stanowić podręczne narzędzie w\xa0walce z\xa0chorobami przewlekłymi.'
	},
	list: {
		baking: {
			pl: `Układanie diety dopasowanej do potrzeb chorego`
		},
		first_aid: { pl: 'Kontrolowanie przyjmowanych leków' },
		strength: {
			pl: 'Monitorowanie zalecanej aktywności fizycznej'
		},
		heart: {
			pl: 'Śledzenie parametrów życiowych'
		}
	}
};
